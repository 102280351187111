.root {
  font-family: "Neue Haas Thin";
}

@font-face{
  font-family:"Neue Haas Thin";
  src: url("./asset/Fonts/cddfddeff0f66d9f03e9df2585ea819e.woff2") format("woff2"),
  url("./asset/Fonts/cddfddeff0f66d9f03e9df2585ea819e.woff") format("woff"),
  url("./asset/Fonts/NeueHaasDisplayRoman.ttf") format("truetype");
}

@font-face{
  font-family:"Helvetica LT W05 Light";
  src:url("./asset/Fonts/5183967/2fa6187b-05d2-489e-acb4-75e984964536.woff2") format("woff2"),url("./asset/Fonts/5183967/6825e726-6dd5-46c9-9bd7-faa4339d0d53.woff") format("woff");
}
@font-face{
  font-family:"Helvetica LT W05 Bold Oblique";
  src:url("./asset/Fonts/5221335/857b7224-af11-4c4c-ba35-303b82c6d48b.woff2") format("woff2"),url("./asset/Fonts/5221335/1bbd5441-5154-44b1-89ca-d050b2644c20.woff") format("woff");
}
@font-face{
  font-family:"Helvetica LT W05 Roman";
  src:url("./asset/Fonts/5225798/4a2626c5-30e7-4564-9b08-a9702d4b442c.woff2") format("woff2"),url("./asset/Fonts/5225798/aaaafa6d-432c-4974-a46b-a2a07c47322e.woff") format("woff");
}
@font-face{
  font-family:"Helvetica LT W05 Black";
  src:url("./asset/Fonts/5228064/97ee7c73-e0f1-435e-9b0f-9efca67239e9.woff2") format("woff2"),url("./asset/Fonts/5228064/dec67bbe-29c2-427e-97fd-d9d89b3b4a16.woff") format("woff");
}
@font-face{
  font-family:"Helvetica LT W05 Light Oblique";
  src:url("./asset/Fonts/5312449/19ea1925-97c5-4c50-b3ae-b9a7831d23b9.woff2") format("woff2"),url("./asset/Fonts/5312449/011ed023-9f80-472f-a1d2-62952a7403ca.woff") format("woff");
}
@font-face{
  font-family:"Helvetica LT W05 Oblique";
  src:url("./asset/Fonts/5313891/4b8f4953-267e-47cf-be2a-d56d0530b615.woff2") format("woff2"),url("./asset/Fonts/5313891/8f5a4c4b-ea65-42d7-9289-b6b3f07d9125.woff") format("woff");
}
@font-face{
  font-family:"Helvetica LT W05 Black Oblique";
  src:url("./asset/Fonts/5340159/8c5baf91-cba1-4bc0-9c2d-fa932365abaf.woff2") format("woff2"),url("./asset/Fonts/5340159/576d0711-08c1-47f8-8958-78d4092c852f.woff") format("woff");
}
@font-face{
  font-family:"Helvetica LT W05 Bold";
  src:url("./asset/Fonts/5346886/f8217c53-d092-4301-96c4-c493faf448db.woff2") format("woff2"),url("./asset/Fonts/5346886/ee50e0a9-594b-4771-9d28-d2f79db50a39.woff") format("woff");
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
